/**
* Generated automatically at built-time (2025-04-04T12:05:40.434Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem, isPathfinderAware, mangleTemplateProps } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
const templates=props.templates.map(template=>{if (!isPathfinderAware(template.component, template.props)) {return template;} return {...template,props: {...template.props, ...mangleTemplateProps(template.component, template.props, props.pathfinder)}}})
return mergeTemplateItems(defaultTemplate, templates).map(createLazyTemplateItem);
}
Template.mapValueToProps = value => ({ pathfinder: value.pathfinder });
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-marque",templateKey: "sites/76-fc2e8f03-588d-4a86-9f2a-ed7d6734cac1"};